import {DashboardMenuOption} from 'components/dashboard-menu/constants'

export const CONTENT_POOL_FILTERS = `${DashboardMenuOption.content}/filters`

export const SHOW_FILTER_TYPE = 'SHOW_FILTER_TYPE'
export const ADD_FILTER = 'ADD_FILTER'
export const FILTER_ADDED = 'FILTER_ADDED'
export const EDIT_FILTER = 'EDIT_FILTER'
export const FILTER_EDITED = 'FILTER_EDITED'
export const TOGGLE_SOURCE_FILTER = 'TOGGLE_SOURCE_FILTER'
export const LOAD_FILTERS = 'LOAD_FILTERS'
export const LOAD_FILTERED_SOURCES = 'LOAD_FILTERED_SOURCES'
export const EXPAND_SOURCE_FILTERS = 'EXPAND_SOURCE_FILTERS'
export const SHOW_FILTERED_SOURCE_TYPES = 'SHOW_FILTERED_SOURCE_TYPES'
export const SOURCE_FILTER_CREATED = 'SOURCE_FILTER_CREATED'
export const SOURCE_FILTER_UPDATED = 'SOURCE_FILTER_UPDATED'
export const APPLY_BULK_FILTER = 'APPLY_BULK_FILTER'
export const ENTER_EDIT = 'ENTER_EDIT'
export const ENTERED_EDIT = 'ENTERED_EDIT'
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const FILTER_UPDATED = 'FILTER_UPDATED'
export const BULK_FILTER_APPLIED = 'BULK_FILTER_APPLIED'

export const FILTER_APPLIES_ALL = 'all'
export const FILTER_APPLIES_SELECTED = 'selected'
export const FILTER_APPLIES_NONE = 'none'

export const CUSTOM = 'Custom'
export const ALL_SOURCES = 'All Sources'
export const NONE = 'None'

export const FILTER_SCOPE = 'community_filters'
