import {
  Grid,
  GridColumn as Column,
  GridNoRecords as NoRecords,
} from '@progress/kendo-react-grid'
import { HeaderComponent } from 'components/header/component'
import { AddFilter } from 'components/icons/addfilter'
import { ExcludeFilter } from 'components/icons/excludefilter'
import { IncludeFilter } from 'components/icons/includefilter'
import { Input } from 'components/input/component'
import { TabMenu } from 'components/tab-menu/component'
import { EditableDropdownCell } from 'generic/editCell'
import { isTruthy } from 'generic/utility'
import { orderBy } from 'lodash'
import { pick, pipe, values } from 'lodash/fp'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { ModalStateProps } from 'shared/modals'
import { ContentFilterTypes } from 'shared_server_client/constants'
import '../styles.css'
import * as Actions from './actions'
import { SourceFilterCountCell } from './kendocells/sourceFilterCountCell'
import { FilterModal } from './modals'
import { SourceFilterDetailComponent } from './source-filter-detail-component'
import { Filter } from './types'

interface StateProps {
  data: Filter[]
  isLoadingSources: boolean,
  selectedFilterType: string
  byFilterType: any
  modalState: ModalStateProps
}

interface DispatchProps {
  showFilterType: any
  addFilter: any
  toggleFilter: any
  loadFilteredSources: any
  toggleSourceFilter: any
  expandSourceFilters: any
  showSourceFilterType: any
  openModal: any
  closeModal: any
  updateSubscriptionFilter: any,
  enterEdit: any,
  cancelEdit: any,
  updateField: any,
  updateFilter: any,
}

type Props = StateProps & DispatchProps

const pluck = pipe(
  pick,
  values,
)

const expandChange = (expandSourceFilters, loadFilteredSources) => ({dataItem, value}) => {
  expandSourceFilters(dataItem.id, value)
  if (!value || dataItem.subscriptions) {
    return
  }
  loadFilteredSources(dataItem.id)
}

const ContentPoolFiltersComponent: FunctionComponent<Props> = ({
  data,
  byFilterType,
  isLoadingSources,
  openModal,
  closeModal,
  selectedFilterType,
  showFilterType,
  toggleFilter,
  toggleSourceFilter,
  expandSourceFilters,
  loadFilteredSources,
  showSourceFilterType,
  addFilter,
  modalState,
  updateSubscriptionFilter,
  enterEdit,
  cancelEdit,
  updateField,
  updateFilter,
}) => {
  const filters = orderBy(pluck(byFilterType[selectedFilterType], data).filter((r) => isTruthy(r.is_active)), 'id', 'desc')
  return (
    <div className="filters-wrapper">
      <FilterModal
        openModal={openModal} closeModal={closeModal}
        data={modalState} title="Filter Added"/>
      <div className="header-container">
        <HeaderComponent
          title={'FILTERS'}
          subTitle={'Content Filters'}
          description={['Use filters to pull in content that is relevant to your topic.']}
        />
        <div className="filterinstructions">
          <div className="filterwrap1">
          <IncludeFilter/>
            <h6> Include filter </h6>
            <div className="text12">An article will <strong>only </strong>
            come in if it matches one of these topics.</div>
          </div>
          <div className="filterwrap2">
          <ExcludeFilter/>
            <h6>Exclude filter</h6>
              <div className="text12"><strong>No </strong>
              articles will come in that match one or more of these topic.</div>
            </div>
        </div>
      </div>
      <TabMenu
        menus={[
          {name: 'Include', key: ContentFilterTypes.whitelist, component: null},
          {name: 'Exclude', key: ContentFilterTypes.blacklist, component: null},
        ]}
        selectedItemKey={selectedFilterType}
        onSelect={({key}) => showFilterType(key)}
      />
      <div className="add-filter">
        <div className="quicktip">
        <h6>How To Add A Filter</h6>
        <p>
          Create a new filter entering keywords, tags or topics.  Then hit enter when done.
        </p>
        </div>
        <div className="inputtext"><div className="addfiltericon"><AddFilter/></div><p>Enter filter</p></div>
        <Input
          className="filter-input"
          placeholder={`Type ${selectedFilterType ===  'whitelist' ? 'Include' : 'Exclude'} keywords, tags, or topics`}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              const target = event.target as HTMLInputElement
              addFilter(selectedFilterType, target.value)
              target.value = ''
            }
          }}
        />
          <p className="inputtextbottom">Hit Enter When Done</p>
        </div>
      <Grid
        className="filter-details"
        data={filters}
        onItemChange={(e) => {
          if (e.field === 'is_active') {
            toggleFilter(e.dataItem.id, e.value);
          } else {
            updateField(e.dataItem.id, e.field, e.value);
          }
        }}
        editField="editing.isEditing"
        detail={(props) => (
          <SourceFilterDetailComponent
            {...props}
            isLoadingSources={isLoadingSources}
            toggleSubscription={toggleSourceFilter}
            showSourceFilterType={showSourceFilterType}
          />
        )}
        expandField="expanded"
        scrollable="none"
      >
        <NoRecords>You have no filters :( create one!</NoRecords>
        <Column field="tags" title="Topic, Keyword, or Phrase" editable={true} />
        <Column
          field="applies_to"
          title="Filter Applies To"
          cell={(props) => (
            <SourceFilterCountCell {...props} onExpand={expandChange(expandSourceFilters, loadFilteredSources)} />
          )}
          editable={false}
        />
        <Column cell={(props) => (
          <EditableDropdownCell {...props}
              canInactive={true}
              canEdit={true}
              onSave={updateFilter}
              onEdit={enterEdit}
              onCancel={cancelEdit}
              inactiveLabel="Archive"
              canApplyFilterToAll={true}
              canApplyFilterToNone={true}
              onApplyToAllFilter={updateSubscriptionFilter}/>
        )} />
      </Grid>
    </div>
  )
}

export const ContentPoolFilters = connect<StateProps, DispatchProps>(
  (state: any) => {
    const {data, selectedFilterType, byFilterType, isLoadingSources} = state.community_filters.data
    return {data, selectedFilterType, byFilterType, isLoadingSources, modalState: state.community_filters.modals}
  },
  {
    addFilter: Actions.addFilter,
    toggleFilter: Actions.toggleFilter,
    expandSourceFilters: Actions.expandSourceFilters,
    loadFilteredSources: Actions.loadFilteredSources,
    toggleSourceFilter: Actions.toggleSourceFilter,
    showFilterType: Actions.showFilterType,
    showSourceFilterType: Actions.showSourceFilterType,
    openModal: Actions.modalActions.openModal,
    closeModal: Actions.modalActions.closeModal,
    updateSubscriptionFilter: Actions.updateSubscriptionFilter,
    enterEdit: Actions.enterEdit,
    cancelEdit: Actions.cancelEdit,
    updateField: Actions.updateField,
    updateFilter: Actions.updateFilter,
  },
)(ContentPoolFiltersComponent)
