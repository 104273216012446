import {DashboardMenuOption} from 'components/dashboard-menu/constants'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { animateScroll as scroll} from 'react-scroll'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'
import {
  EditArticleProps,
  EditArticleState,
  initialEditArticleState,
  UploadedImage,
} from '../types'
import { AddArticlePreview } from '../../add_article_preview'

class EditArticleComponent extends RasaReactComponent<EditArticleProps, EditArticleState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(p: EditArticleProps) {
    super(p, 'rasaCommunityArticle', initialEditArticleState)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.clearImage = this.clearImage.bind(this)
    this.updateImageFromUrl = this.updateImageFromUrl.bind(this)
  }

  public componentDidMount() {
    const firstId = this.context.store.getState().app.params.id
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.loadOnRouting(firstId, `${DashboardMenuOption.content}/editarticle`),
    ])
    .then(([activeCommunity, _]) => {
      this.communityId = activeCommunity.communityId
      //this method doesn't work here. :(
      this.scrollToTop();
    })
  }

  public render = () => {
    return <div className="mobile-add-article">
        <div className="mobile-edit-article-mode">
          <Label>
            <h6>Title</h6>
          </Label>
          <Input value={this.props.data.title} onChange={(e) => this.propertyChanged('title', e.target.value)}/>
          <Label>
            <h6>Source</h6>
          </Label>
          <Input value={this.props.data.site_name} onChange={(e) => this.propertyChanged('site_name', e.target.value)}/>
          <Label>
            <h6>Excerpt</h6>
          </Label>
          <Input type="textarea" value={this.props.data.description}
                                 onChange={(e) => this.propertyChanged('description', e.target.value)} />
            <div className="mobile-edit-bottom-section">
            <h6>Article Preview</h6>
              <Row>
              <Col className="mobile-image-box"sm={3}>
                <AddArticlePreview isUploading={this.state.isUploading}
                                   imageUrl={this.props.data.hosted_image_url}
                                   updateImageUrl={this.updateImageFromUrl}
                                   uploadImage={this.uploadImage} clearImage={this.clearImage}
                                   data={this.props.data} description={this.props.data.description}
                                   imageHeight={this.props.data.image_height} imageWidth={this.props.data.image_width} />
              </Col>
              </Row>
              <br/>
            </div>
            </div>
              {this.state.isLoading ?
              <div>
                <Loading size="64"/>
              </div>
              :
              <div className="mobile-edit-post-end">
                <Button value="Save Changes"
                        disabled={!this.state.dirty || this.state.isLoading}
                        className="save-edit-button"
                        onClick={this.saveArticle}>
                    Save Changes
                </Button>
                <Button value="Add Article"
                        onClick={this.cancelEdit}>
                    Cancel
                </Button>
              </div>
              }
        </div>
  }

  public propertyChanged(property: string, newValue: any) {
    super.propertyChanged(property, newValue)
    this.setState({
      dirty: true,
    })
  }

  public propertiesChanged(properties: any) {
    super.propertiesChanged(properties)
    this.setState({
      dirty: true,
    })
  }

  protected clearImage() {
    this.propertiesChanged({
      hosted_image_url: '',
      image_height: null,
      image_url: '',
      image_width: null,
    })
  }

  protected updateImageFromUrl(imageUrl) {
    this.propertiesChanged({
      image_url: imageUrl,
      hosted_image_url: imageUrl
    })
  }

  private scrollToTop() {
    scroll.scrollToTop();
  }

  private uploadImage(image: any) {
    this.setState({
      isUploading: true,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: UploadedImage) => {
        this.setState({
          error: true,
          isUploading: false,
        })
        this.propertiesChanged({
          hosted_image_url: hostedImage.url,
          image_height: hostedImage.height,
          image_width: hostedImage.width,
          image_url: hostedImage.url,
        })
      })
      .catch(() => {
        this.setState({
          error: true,
          isLoading: false,
        })
      })
  }

  private saveArticle = () => {
    this.setState({
      error: false,
      isLoading: true,
    }, () => {
      this.saveRecord(this.props.data.id).then(() => this.setState({isLoading: false}))
          .then(() => {
            this.props.push(DashboardMenuOption.content)
          })
    })
  }

  private cancelEdit = () => {
    this.props.push(DashboardMenuOption.content)
  }

}

export const MobileContentPoolEditArticle = GenericRedux.registerNewNestedComponent(
  EditArticleComponent, 'edit_article_mobile')
