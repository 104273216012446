import {keyBy, mapValues, merge, property, reduce} from 'lodash'
import {errorConstant, requestConstant, successConstant} from 'request'
import * as Modals from 'shared/modals'
import { ContentFilterTypes } from 'shared_server_client/constants'
import * as Constants from './constants'

const initialState = {
  data: {},
  byFilterType: {},
  selectedFilterType: ContentFilterTypes.whitelist,
}

const groupBy = (col, keyFn, valFn = (a, ..._) => a) =>
  reduce(
    col,
    (acc, ...rest) => {
      const key = keyFn(...rest)
      const value = valFn(...rest)
      const existing = acc[key]

      return {
        ...acc,
        [key]: !existing ? [value] : existing.concat(value),
      }
    },
    {},
  )

const dataReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case successConstant(Constants.LOAD_FILTERS): {
      const [dataset] = action.payload.response
      const indexedFilters = keyBy(dataset, 'id')

      return {
        ...state,
        data: indexedFilters,
        byFilterType: groupBy(indexedFilters, property('type'), (_filter, key) => key),
      }
    }

    case Constants.SHOW_FILTER_TYPE:
      return {
        ...state,
        selectedFilterType: action.filterType,
      }

    case requestConstant(Constants.LOAD_FILTERED_SOURCES):
      return {
        ...state,
        isLoadingSources: true,
      }
    case errorConstant(Constants.LOAD_FILTERED_SOURCES):
      return {
        ...state,
        isLoadingSources: false,
      }
    case successConstant(Constants.LOAD_FILTERED_SOURCES): {
      const [dataset] = action.payload.response

      // coerce mysql tinyint to boolean
      dataset.forEach((row) => {
        row.is_active = !!row.is_active
      })

      const sourcesByFilterGroup = groupBy(dataset, property('filter_group_id'))
      const mappedSourcesByFilterGroup = mapValues(sourcesByFilterGroup, (subscriptions) => ({subscriptions}))

      return {
        ...state,
        data: merge({}, state.data, mappedSourcesByFilterGroup),
        isLoadingSources: false,
      }
    }

    case Constants.EDIT_FILTER: {
      const filter = state.data[action.id]
      const update = {
        ...filter,
        applies_to: action.appliesTo || filter.applies_to,
        is_active: action.isActive === undefined ? filter.is_active : action.isActive,
      }

      return {
        ...state,
        data: {...state.data, [action.id]: update},
      }
    }

    case Constants.EXPAND_SOURCE_FILTERS: {
      const filter = state.data[action.filterGroupId]

      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: {...filter, expanded: action.value}},
      }
    }

    case Constants.TOGGLE_SOURCE_FILTER: {
      const filter = state.data[action.filterGroupId]
      const sourceFilters = filter.subscriptions.map((sf) => {
        if (action.id && action.id === sf.id) {
          return {...sf, is_active: action.isActive}
        }

        if (!action.id && sf.subscription_id === action.subscriptionId) {
          return {...sf, is_active: action.isActive}
        }

        return sf
      })

      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: {...filter, subscriptions: sourceFilters}},
      }
    }

    case Constants.SHOW_FILTERED_SOURCE_TYPES: {
      const filter = state.data[action.filterGroupId]

      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: {...filter, visibleSourceTypes: action.sourceTypes}},
      }
    }

    case Constants.FILTER_ADDED: {
      const {byFilterType, selectedFilterType} = state
      const newFilter = {
        expanded: true,
        id: action.filterGroupId,
        type: selectedFilterType,
        name: action.tagName,
        tags: action.tagName,
        applies_to: Constants.NONE,
        is_active: true,
      }

      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: newFilter},
        byFilterType: {
          ...byFilterType,
          [selectedFilterType]: [`${action.filterGroupId}`].concat(byFilterType[selectedFilterType]),
        },
      }
    }

    case Constants.SOURCE_FILTER_CREATED: {
      const filter = state.data[action.filterGroupId]
      const sourceFilters = filter.subscriptions.map((sf) =>
        sf.subscription_id === action.subscriptionId ? {...sf, id: action.id} : sf,
      )

      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: {...filter, subscriptions: sourceFilters}},
      }
    }
    case Constants.BULK_FILTER_APPLIED: {
      const filter = state.data[action.applyToFilter.id]
      const update = {
        ...filter,
        subscriptions: action.applyToFilter.subscriptions,
      }
      return {
        ...state,
        data: {...state.data, [action.applyToFilter.id]: update},
      }
    }
    case Constants.ENTERED_EDIT: {
      const filter = !action.isEditing ? state.data[action.filterGroupId].editing.initialValue
      : state.data[action.filterGroupId]
      const update = {
        ...filter,
        editing: {
          ...filter.editing,
          initialValue: filter,
          isEditing: action.isEditing,
        },
      }
      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: update},
      }
    }
    case Constants.UPDATE_FIELD: {
      const filter = state.data[action.filterGroupId]
      const update = {
        ...filter,
        [action.field]: action.value,
      }
      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: update},
      }
    }
    case Constants.FILTER_UPDATED: {
      const filter = state.data[action.filterGroupId]
      const update = {
        ...filter,
        editing: {
          ...filter.editing,
          initialValue: filter,
          isEditing: false,
        },
      }
      return {
        ...state,
        data: {...state.data, [action.filterGroupId]: update},
      }
    }
    default:
      return state
  }
}

export const reducer = Modals.createReducer(dataReducer, Constants.FILTER_SCOPE, [Constants.FILTER_ADDED])
