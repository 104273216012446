import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { LinkOutIcon } from 'components/icons/link-out'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { startCase } from 'lodash'
import * as React from 'react'
import { animateScroll as scroll} from 'react-scroll'
import { Badge, Button, Col, Input, Label, Row } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { IMAGE } from 'shared_server_client/constants'
import { AddArticlePreview } from '../add_article_preview'
import {
  EditArticleProps,
  EditArticleState,
  initialEditArticleState,
} from './types'

class EditArticleComponent extends RasaReactComponent<EditArticleProps, EditArticleState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(p: EditArticleProps) {
    super(p, 'rasaCommunityArticle', initialEditArticleState)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.clearImage = this.clearImage.bind(this)
    this.updateImageFromUrl = this.updateImageFromUrl.bind(this)
  }

  public componentDidMount() {
    const firstId = this.context.store.getState().app.params.id
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.loadOnRouting(firstId, `${DashboardMenuOption.content}/editarticle`),
    ])
    .then(([activeCommunity, _]) => {
      this.communityId = activeCommunity.communityId
      //this method doesn't work here. :(
      this.scrollToTop();
    })
  }

  public render = () => {
    return <div className="add-article">
      <Row>
        <Col lg="10" className="headertext">
          <h6>Edit Article</h6>
          <div className="section-header-text">Edit An Existing Article</div>
        </Col>
      </Row>
      <Row className="url">
        <Col lg="10">
          <a href={this.props.data.url} target="_blank" rel="noopener">
            <LinkOutIcon/>
            <span>{this.props.data.url}</span>
          </a>
        </Col>
      </Row>
    {this.state.isLoading
    ?
      <div>
        <Loading size="64"/>
      </div>
    : ( !this.state.isLoading && !this.props.data.id )
    ?
      <div>
        <h3>Something has gone wrong</h3>
      </div>
    :
      <div>
        <div className="edit-article-mode">
          <Row>
            <Col sm={8}>
              <Label>
                <h6>Title</h6>
              </Label>
              <Input value={this.props.data.title} onChange={(e) => this.propertyChanged('title', e.target.value)}/>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Label>
                <h6>Source</h6>
              </Label>
              <Input value={this.props.data.site_name}
                     onChange={(e) => this.propertyChanged('site_name', e.target.value)}/>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Label>
                <h6>Excerpt</h6>
              </Label>
              <Input type="textarea"
                    value={this.props.data.description}
                    onChange={(e) => this.propertyChanged('description', e.target.value)} />
              <Button className="generate"
                      onClick={() => this.props.openModal(
                        GeneratedSummaryModalComponent.key, {source: 'edit-article'})}>
                <span>Generate with ChatGPT</span>
              </Button>
            </Col>
          </Row>
          <div className="edit-bottom-section article-preview">
            <Row>
              <Col sm={8}>
                <h6>Article Preview</h6>
                <AddArticlePreview isUploading={this.state.isUploading}
                  imageUrl={this.props.data.hosted_image_url}
                  updateImageUrl={this.updateImageFromUrl}
                  uploadImage={this.uploadImage} clearImage={this.clearImage}
                  data={this.props.data} description={this.props.data.description}
                  imageHeight={this.props.data.image_height} imageWidth={this.props.data.image_width} />
              </Col>
            </Row>
            <Row sm={4}>
              <div className="custom-tag-editor title-words">
                <h6>Tags</h6>
                <Input
                  max={50}
                  id="custom-tag-input"
                  className="tag-input"
                  placeholder={'Type new tags here'}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      const target = event.target as HTMLInputElement
                      this.addTag(target.value)
                      target.value = ''
                    }
                  }}
                />
                <p id="custom-input-text-bottom" className="inputtextbottom">Hit Enter When Done</p>
                <div className="tag-container bottom-space">
                  {this.props.data.custom_tags
                  && this.props.data.custom_tags.split(',').map((tag: string, index: number) =>
                  <Badge className="custom" key={`psd-${this.props.data.id}-${index}`}
                    color="secondary">{startCase(tag.toLowerCase())} <span className="badge-close"
                    onClick={(e) => this.removeTag({tag})}>x</span></Badge>)}
                </div>
                <div className="tag-container">
                  {this.props.data.nlp_tags
                  && this.props.data.nlp_tags.split(',').map((tag: string, index: number) =>
                  <Badge className="nlp" key={`${this.props.data.id}-${index}`}
                    color="secondary">{tag}</Badge>)}
                </div>
                <div className="tag-container">
                  {this.props.data.source_tags
                  && this.props.data.source_tags.split(',').map((tag: string, index: number) =>
                  <Badge className="source" key={`${this.props.data.id}-${index}`}
                    color="secondary">{tag}</Badge>)}
                </div>
              </div>
            </Row>
            <br/>
          </div>
        </div>
        <div className="edit-post-end">
          <Button value="Save Changes"
                  disabled={!this.state.dirty || this.state.isLoading}
                  className="save-edit-button"
                  onClick={this.saveArticle}>
              Save Changes
          </Button>
          <Button value="Add Article"
                  onClick={this.cancelEdit}>
              Cancel
          </Button>
        </div>
      </div>
    }
    <GeneratedSummaryModalComponent
        saveModal={(key, modalData) => {
          if ( modalData.description ) {
            this.propertyChanged('description', modalData.description)
          }
          return this.props.closeModal(key)
        }}
        title={this.props.data.title}
        description={this.props.data.description}
        url={this.props.data.url}
        data={this.props.modals}
        closeModal={this.props.closeModal}
        updateModal={this.props.updateModal}
        size={Modals.ModalSize.Large}/>
    </div>
  }

  public propertyChanged(property: string, newValue: any) {
    super.propertyChanged(property, newValue)
    this.setState({
      dirty: true,
    })
  }

  public propertiesChanged(properties: any) {
    super.propertiesChanged(properties)
    this.setState({
      dirty: true,
    })
  }

  protected updateImageFromUrl(imageUrl) {
    this.propertiesChanged({
      image_url: imageUrl,
    })
  }

  protected addTag(tag) {
    const customTags = !this.props.data.custom_tags ? [] : this.props.data.custom_tags.split(',')
    if (tag && customTags.indexOf(tag.trim()) < 0 && customTags.length < 5) {
      customTags.push(tag.trim())
      this.propertiesChanged({
        custom_tags: customTags.join(','),
      })
    }
  }
  protected removeTag(obj) {
    const customTags = this.props.data.custom_tags.split(',').filter((item) => item !== obj.tag)
    this.propertiesChanged({
      custom_tags: customTags.join(','),
    })
  }

  protected clearImage() {
    this.propertiesChanged({
      blacklist_image_url: this.props.data.image_url,
      hosted_image_url: '',
      image_height: null,
      image_url: '',
      image_width: null,
    })
  }

  private scrollToTop() {
    scroll.scrollToTop();
  }

  private uploadImage(image: any) {
    this.setState({
      isUploading: true,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: any) => {
        this.setState({
          error: true,
          isUploading: false,
        })
        this.propertiesChanged({
          hosted_image_url: hostedImage.url,
          image_height: hostedImage.height,
          image_width: hostedImage.width,
          image_url: hostedImage.url,
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          isUploading: false,
        })
      })
  }

  private saveArticle = () => {
    this.setState({
      error: false,
      isLoading: true,
    }, () => {
      this.saveRecord(this.props.data.id)
        .then(() => this.setState({ isLoading: false }))
        .then(() => {
          this.props.push(DashboardMenuOption.content)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Error while saving, Error = ${error}`)
          this.setState({ isLoading: false })
        })
    })
  }

  private cancelEdit = () => {
    this.props.push(DashboardMenuOption.content)
  }

}

export const ContentPoolEditArticle = GenericRedux.registerNewComponentWithModals(
  EditArticleComponent,
  'edit_article',
  [GeneratedSummaryModalComponent.key],
)
