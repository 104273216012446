import * as Request from 'request'
import * as Modals from 'shared/modals'

import {StaticDataset} from '../../../generic/static_dataset'

import * as Constants from './constants'

export const loadCommunityFiltersDataset = (communityId, authToken) =>
  Request.requestActionCreator(Constants.LOAD_FILTERS)({
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: 'get',
    url: StaticDataset.url('communityFilters', communityId),
  })

export const loadCommunityFilterSubscriptionsDataset = (communityId, filterGroupId, authToken) =>
  Request.requestActionCreator(Constants.LOAD_FILTERED_SOURCES)({
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: 'get',
    url: StaticDataset.url('communityFilteredSubscriptions', communityId, null, null, null, null, [
      {param: 'filterGroupId', value: filterGroupId},
    ]),
  })

export const loadFilteredSources = (filterGroupId) => ({type: Constants.LOAD_FILTERED_SOURCES, filterGroupId})

export const addFilter = (filterType, tagString) => ({
  type: Constants.ADD_FILTER,
  filterType,
  tags: tagString,
})

export const toggleFilter = (id, isActive) => ({type: Constants.EDIT_FILTER, id, isActive})
export const updateFilter = (item) => ({type: Constants.UPDATE_FILTER, id: item.id, tags: item.tags})
export const updateField = (filterGroupId, field, value) => ({
  type: Constants.UPDATE_FIELD,
  filterGroupId, field, value,
})

export const expandSourceFilters = (filterGroupId, value) => ({
  type: Constants.EXPAND_SOURCE_FILTERS,
  filterGroupId,
  value,
})

export const toggleSourceFilter = (id, subscriptionId, filterGroupId, filterType, isActive) => ({
  type: Constants.TOGGLE_SOURCE_FILTER,
  id,
  isActive,
  subscriptionId,
  filterType,
  filterGroupId,
})

export const showFilterType = (filterType) => ({type: Constants.SHOW_FILTER_TYPE, filterType})

export const showSourceFilterType = (filterGroupId, sourceTypes) => ({
  type: Constants.SHOW_FILTERED_SOURCE_TYPES,
  filterGroupId,
  sourceTypes,
})

export const enterEdit = (item) => ({type: Constants.ENTER_EDIT, id: item.id, isEditing: true})
export const cancelEdit = (item) => ({type: Constants.ENTER_EDIT, id: item.id, isEditing: false})
export const updateSubscriptionFilter = (id, isActive) =>
({type: Constants.APPLY_BULK_FILTER, id, isActive, appliesTo: isActive ? 'all' : 'none'})
export const modalActions: Modals.ModalActionList = Modals.createActionList(Constants.FILTER_SCOPE)
