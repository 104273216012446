import { Fade } from 'components/fade'
import { FacebookIcon } from 'components/icons/facebook'
import { NextIcon } from 'components/icons/next'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  options: string,
  type: string,
}

type AddSourceProps = GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  existingSources: any[],
  isLoading: boolean,
  name: string,
  pageFeeds: any[],
  pageFeedsFound: boolean,
  selectedCommunity: string,
  selectedPage: any,
  sourcePages: any,
  sourcePagesFound: boolean,
  sourcePageUrl: string,
}

class ContentPoolAddFacebookSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource', {
      existingSources: [],
      isLoading: false,
      name: '',
      pageFeeds: [],
      pageFeedsFound: true,
      selectedCommunity: null,
      selectedPage: {},
      sourcePages: [],
      sourcePagesFound: true,
      sourcePageUrl: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({
            existingSources: response[0] || [],
            selectedCommunity: activeCommunity.communityId,
          })
        })
    })
  }

  public render = () => {
    return <div className={`${this.context.store.getState().config.isMobile ? 'add-facebook-wrapper-mobile' : 'add-facebook-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add New Facebook Page</div>
      <p className="section-detail-text">Add a Facebook page and we will pull articles.</p>
      {isEmpty(this.state.selectedPage) &&
        <div className="facebook sources">
          <div className="image">
            <FacebookIcon svgwidth={16.48} svgheight={16.48}/>
          </div>
          <div className="words">
            <h2>Facebook</h2>
            <p>What's the Facebook page url?</p>
            <div className="input-area">
              <form onSubmit={preventDefaultThen(() => this.searchPages(this.state))}>
                <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                  placeholder="https://www.facebook.com/my-page/"
                  value={this.state.sourcePageUrl}
                  onChange={(e: any) => this.setState({ sourcePageUrl: e.target.value })}/>
                <Button disabled={this.state.sourcePageUrl.trim() === '' || this.state.isLoading}>Next</Button>
              </form>
            </div>
            {!this.state.sourcePagesFound && <span className="warning">We could not locate this URL</span>}
          </div>
        </div>
      }
      {!isEmpty(this.state.selectedPage) &&
        <div className="facebook confirm sources">
          <div className="image">
            <FacebookIcon svgwidth={16.48} svgheight={16.48}/>
          </div>
          <div className="words">
            <h2>Facebook</h2>
            <p>
              <strong className="feed-result-text">We found the page, {this.state.selectedPage.name}.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            {!isValidSourceName(this.state.existingSources, this.state.name) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({name: e.target.value})}
                />
                <Button onClick={() => this.addSource({
                  community_id: this.context.user.activeCommunity.communityId,
                  identifier: this.state.selectedPage.id,
                  name: this.state.name,
                  options: JSON.stringify({ url: this.state.selectedPage.link}),
                  type: SourceTypes.facebook})}
                  disabled={this.state.isLoading ||
                    !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                <Button className="change" onClick={() => this.changeSelection()}>Cancel</Button>
              </form>
            </div>
          </div>
        </div>
      }
      <div className="facebook-search-results">
        {this.state.sourcePages.map((page: any) =>
          <button key={page.id} onClick={(e: any) => this.getFeed(page) }>
            <Fade>
              <div className="result">
                { page.imgixUrl
                  ? <div className="profile-image"><img src={`${page.imgixUrl}?w=100&h=100&fit=crop`} /></div>
                  : (page.cover && page.cover.source)
                    ? <div className="profile-image"><img src={`${page.cover.source}`} /></div>
                    : null
                }
                <div className="info">
                  <div className="title">{page.name}</div>
                  <div className="link">{page.link}</div>
                  <div className="description">{page.category}</div>
                  {page.location ? <div className="title">{page.location.city || 'No City'}, {page.location.country || 'No Country'}</div> : null }
                  <div className="description">{page.about}</div>
                </div>
                <div className="next">
                  <NextIcon/>
                </div>
              </div>
            </Fade>
          </button>)}
      </div>
      {this.state.isLoading && <Loading.Loading size={Loading.SIZES.LARGE}/>}
      {this.state.pageFeeds.length > 0 &&
        <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
          <h4>Recent Articles</h4>
          {this.state.pageFeeds.slice(0, 5).map((feed: any, i: number) =>
          <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
          {!this.state.pageFeedsFound && <span className="warning">We could not find any article.</span>}
        </div>
      }
    </div>
  }

  private searchPages(state) {
    this.setState({isLoading: true}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/facebook/page/search?q=${state.sourcePageUrl}&i=${state.selectedCommunity}&a=dashboard`,
        HttpMethod.GET,
        {},
      )
      .then((sourcePages) => this.setState({
        sourcePages,
        sourcePagesFound: sourcePages.length > 0,
        isLoading: false,
      })),
    )
  }
  private getFeed(page) {
    this.setState({isLoading: true, name: page.name, selectedPage: page, sourcePages: []}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/facebook/page/feeds?q=${page.id}`,
        HttpMethod.GET,
        {},
      )
      .then((pageFeeds) => {
        if (pageFeeds) {
          this.setState({
            pageFeeds: pageFeeds.map((pf) => {
              const article: any = {
                url: pf.permalink_url,
              }
              article.description = (pf.attachments && pf.attachments.data && pf.attachments.data.length &&
              pf.attachments.data[0].description) ? pf.attachments.data[0].description : ''
              article.image = (pf.attachments && pf.attachments.data && pf.attachments.data.length &&
              pf.attachments.data[0].media && pf.attachments.data[0].media.image) ?
              pf.attachments.data[0].media.image.src : ''
              article.title = (pf.attachments && pf.attachments.data && pf.attachments.data.length &&
              pf.attachments.data[0].title) ? pf.attachments.data[0].title : ''
              return article
            }),
            pageFeedsFound: pageFeeds.length > 0,
            isLoading: false,
          })
        }
      }),
    )
  }
  private changeSelection() {
    this.setState({
      pageFeeds: [],
      pageFeedsFound: true,
      selectedPage: {},
      sourcePages: [],
      sourcePagesFound: true,
    })
  }

  private addSource = (source: Source) => {
    this.setState({communityId: this.context.user.activeCommunity.communityId}, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.context.user.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'facebook', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddFacebookSource = GenericRedux.createConnect(ContentPoolAddFacebookSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
