import { DropdownOption } from 'components/dropdown/component'
import * as GenericRedux from 'generic/genericRedux'
import { UpcomingIssue } from 'shared_server_client/types/schedule'
export interface UploadedImage {
  colors: string[],
  location: string,
  url: string,
  height?: number,
  width?: number,
}

// NOTE: Post comes back from /article/find with an attribute "image"
// But when we submit a Post to Save, we expect to send along "image_url"
// This one structure represents both...
export interface Post {
  boost_level?: number,
  description?: string,
  feed?: string,
  content_type?: string,
  hosted_image_url?: string,
  id?: number,
  image?: string,
  image_height?: number,
  image_width?: number,
  image_url?: string,
  is_reserved?: boolean,
  publisher?: string,
  site_name: string,
  title?: string,
  retrieved_url?: string,
  url?: string,
  custom_tags?: string,
  nlp_tags?: string,
  source_tags?: string,
}

export interface EditArticleState {
  dirty: boolean,
  error?: boolean,
  isUploading?: boolean,
}

export const initialEditArticleState: EditArticleState = {
  dirty: false,
}

export interface AddArticleState extends EditArticleState {
  currentTime: Date
  hasScheduledContentAccess: boolean
  isFetched?: boolean
  isFetching?: boolean
  nextIssue?: UpcomingIssue
  sections?: DropdownOption[]
  selectedCommunity: string
  selectedSection: DropdownOption
  timezone: string
  type?: string
  url?: string
}

export const initialAddArticleState: AddArticleState = {
  currentTime: null,
  dirty: false,
  hasScheduledContentAccess: false,
  selectedCommunity: null,
  selectedSection: null,
  timezone: null,
  url: '',
}

export interface EditArticleProps extends GenericRedux.AllComponentPropsWithModal<Post> {
  post?: Post,
}
