import React from 'react'
import * as Modals from 'shared/modals'
import * as Constants from './constants'

export class FilterModal extends Modals.ModalComponent {
  public static key: string = Constants.FILTER_ADDED
  constructor(props: Modals.ModalComponentProps) {
    super(props, FilterModal.key)
  }
  protected renderChildren(data: any) {
    return <div>Filters will only be applied to articles pulling in from this point forward. Filters
      will not be applied retroactively to articles that have already been pulled into the upcoming page.</div>
  }
}

export const emptyState: Modals.ModalStateData = {
  ...Modals.emptyState,
  maxTimesToShow: 1,
}
