import { InstagramIcon } from 'components/icons/instagram'
import { preventDefault } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { CONTENT_ADD_SOURCE_PAGE, DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'

import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  options: string,
  type: string,
}

type AddSourceProps = GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  activeCommunity: any,
  existingSources: any[],
  hasInstagramSource: boolean,
  isComponentLoading: boolean,
  name: string,
  pageFeeds: any[],
  pageFeedsFound: boolean,
  sourcePageUrl: string,
}

class ContentPoolAddInstagramSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource')

    this.state = {
      activeCommunity: null,
      existingSources: [],
      hasInstagramSource: false,
      isComponentLoading: true,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      name: '',
      pageFeeds: [],
      pageFeedsFound: true,
      sourcePageUrl: '',
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({activeCommunity})
      Promise.all([
        new Dataset().loadCommunityDataset('communitySources', activeCommunity.communityId),
        this.getPosts(),
      ])
      .then(([sourcesResponse]) => {
        const existingSources = sourcesResponse[0] || []
        const hasInstagramSource = existingSources.filter((s) => {
          return s.source_type === SourceTypes.instagram && s.is_active
        })
        if (hasInstagramSource.length > 0) {
          this.setState({hasInstagramSource: true})
        } else {
          this.setState({
            existingSources,
            hasInstagramSource: false,
          })
        }
        this.setState({isComponentLoading: false})
      })
      .catch(() => {
        this.setState({isComponentLoading: false})
        // hanlde error in UI
      })
    })
  }

  public render = () => {
    return this.state.isComponentLoading ? <Loading.Loading size={Loading.SIZES.LARGE}/> :
    this.state.hasInstagramSource
    ? <div className={`${this.context.store.getState().config.isMobile ? 'add-instagram-wrapper-mobile' : 'add-instagram-wrapper'}`}>
        <div className="source-exists" >You already have {SourceTypes.instagram} as a source!</div>
        <Button className="change" onClick={() => this.changeSelection()}>Ok</Button>
      </div>
    :
    <div className={`${this.context.store.getState().config.isMobile ? 'add-instagram-wrapper-mobile' : 'add-instagram-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add {SourceTypes.instagram} Source</div>
      <p className="section-detail-text">We will pull articles from your posts.</p>
      <div className="instagram confirm sources">
        <div className="image">
          <InstagramIcon svgwidth={16.48} svgheight={16.48}/>
        </div>
        <div className="words">
          <h2>{SourceTypes.instagram}</h2>
          <p>
            <strong className="feed-result-text">We connected to your posts.</strong>
          </p>
          <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
          {!isValidSourceName(this.state.existingSources, this.state.name) &&
          <div className="invalid-source-name-container">You already have a source with this name</div>}
          <div className="input-area">
            <form onSubmit={preventDefault()}>
              <input autoFocus className="field-textbox"
                value={this.state.name}
                onChange={(e: any) => this.setState({name: e.target.value})}
              />
              <Button
                onClick={() => this.addSource({
                  community_id: this.state.activeCommunity.communityId,
                  identifier: `instagram-${this.state.activeCommunity.communityId}`,
                  name: this.state.name,
                  options: null,
                  type: SourceTypes.instagram})
                }
                disabled={this.state.isComponentLoading ||
                  !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
              <Button className="change" onClick={() => this.changeSelection()}>Cancel</Button>
            </form>
          </div>
        </div>
      </div>
      <div className={`${this.context.store.getState().config.isMobile
        ? 'recent-articles-mobile' : 'recent-articles'}`}>
        <h4>Recent Articles</h4>
        {this.state.pageFeeds.slice(0, 5).map((feed: any, i: number) =>
        <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
        {!this.state.pageFeedsFound && <span className="warning">We could not find any article.</span>}
      </div>
    </div>
  }

  private getPosts() {
    this.setState({isComponentLoading: true}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/instagram/posts/${this.state.activeCommunity.communityId}`,
        HttpMethod.GET,
        {},
      )
      .then((myPosts) => {
        if (myPosts) {
          this.setState({
            name: myPosts.length ? myPosts[0].username : '',
            pageFeeds: myPosts.map((p) => {
              const article: any = {
                url: p.permalink,
              }
              article.image = p.thumbnail_url
              article.title = p.caption ? p.caption : ''
              return article
            }),
            pageFeedsFound: myPosts.length > 0,
          })
        }
      }),
    )
  }
  private changeSelection() {
    this.setState({
      pageFeeds: [],
      pageFeedsFound: true,
    })
    this.props.push(CONTENT_ADD_SOURCE_PAGE)
  }

  private addSource = (source: Source) => {
    this.setState({communityId: this.state.activeCommunity.communityId}, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.state.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'instagram', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddInstagramSource = GenericRedux.createConnect(ContentPoolAddInstagramSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
