import {GridCellProps} from '@progress/kendo-react-grid'
import {ExpandoButton} from 'components/expando-button'
import {countBy, get} from 'lodash'
import React, {FunctionComponent} from 'react'

import * as Constants from '../constants'
import {Filter} from '../types'

interface FilterExpandEvent {
  dataItem: Filter
  value: boolean
}

type Props = GridCellProps & {onExpand: (e: FilterExpandEvent) => any}

export const SourceFilterCountCell: FunctionComponent<Props> = ({dataItem, onExpand}) => {
  const counts = countBy(dataItem.subscriptions, 'is_active')
  const activeCount = get(counts, 'true', dataItem.active_count)

  return (
    <td className="filter-apply-cell">
      <ExpandoButton expanded={!!dataItem.expanded} onClick={() => onExpand({dataItem, value: !dataItem.expanded})}>
        {dataItem.applies_to === Constants.FILTER_APPLIES_SELECTED ? Constants.CUSTOM :
        dataItem.applies_to === Constants.FILTER_APPLIES_ALL ? Constants.ALL_SOURCES : Constants.NONE}
      </ExpandoButton>
      <span className="apply-count">
        {dataItem.applies_to === Constants.FILTER_APPLIES_SELECTED ?
          `${activeCount || Constants.NONE} Selected` : null}
      </span>
    </td>
  )
}
