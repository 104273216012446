import { Grid, GridCellProps, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid'
import { canAccessSegment } from 'app/utils'
import { HeaderComponent } from 'components/header/component'
import { RSSFeedIcon } from 'components/icons/rssfeed'
import { TwitterIcon } from 'components/icons/twittersmall'
import { Loading } from 'components/loading'
import { TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import isNil from 'lodash/isNil'
import * as React from 'react'
import { Button, Input, Row } from 'reactstrap'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { DEFAULT_COMMUNITY_PARTNER_CODES, SourceTypes } from '../../../shared_server_client/constants'
import {ActiveCell} from '../kendocells'
import { isValidUpdatedSourceName } from '../utility/utils'
import './edit-source.scss'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DEFAULT_SEGMENT_CODE } from '../constants'

export interface Source {
  source_type: string,
  name: string,
  source_url: string,
  id: string|number,
  whitelist_matches: number,
  blacklist_matches: number,
  community_partner_code: string,
}

export interface Filter {
  applies_to: string,
  filter_group_id: number,
  id: number,
  is_active: boolean,
  name: string,
  type: string,
}

interface EditSourceState {
  name?: string,
  editName?: boolean,
  existingSources: any[],
  communityId?: string,
  filters: Filter[],
  hasIncludeExcludeConfigAccess: boolean,
  hasSegmentAccess: boolean
  id: string,
  includeExcludeLimit: number,
  key: string,
  selectedSegmentCode: string,
  segments: any[],
}

type EditSourceProps = GenericRedux.AllComponentProps<Source>

const FiltersMenus = [
  {
    name: 'Include',
    key: 'whitelist',
    component: null,
  },
  {
    name: 'Exclude',
    key: 'blacklist',
    component: null,
  },
]

class EditSourceComponent extends RasaReactComponent<EditSourceProps, EditSourceState> {
  public static contextType = RasaContext;
  constructor(p: any) {
    super(p, 'communitySource')
    this.state = {
      existingSources: [],
      filters: [],
      hasIncludeExcludeConfigAccess: false,
      hasSegmentAccess: false,
      key: 'whitelist',
      isDirty: false,
      isLoading: false,
      isSaving: false,
      id: null,
      includeExcludeLimit: 0,
      name: '',
      selectedSegmentCode: DEFAULT_SEGMENT_CODE,
      segments: [],
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      const includeExcludeFeature = activeCommunity.billingInfo.currentPlan.details.filter((x) =>
        x.billing_plan_code === BillingPlanDetailCode.ADVANCED_SOURCE_CONFIG)[0]
      const hasIncludeExcludeConfigAccess = includeExcludeFeature && includeExcludeFeature.limit_val !== 0
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
      communityId: activeCommunity.communityId,
      hasIncludeExcludeConfigAccess,
      includeExcludeLimit: includeExcludeFeature ? includeExcludeFeature.limit_val : 0})
      canAccessSegment(avlFeatures, activeCommunity.communityId).then((canAccess) => {
        this.setState({hasSegmentAccess: canAccess})
      })
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({existingSources: response[0] || [], name: this.props.data.name})
        })
    })
    const id: string = this.context.store.getState().app.params.id
    this.loadOnRouting(id, '/content/editsource').then(() => {
      new Dataset().loadCommunityDataset(
          'communitySubscriptionFilters',
          this.state.communityId,
          [{param: 'sourceId', value: id}])
        .then((response) => {
          this.setState({filters: response[0], id})
      })
    })
  }

  public render = () => {
    return(
    <div className="edit-source">
      <Row>
        <HeaderComponent
          title={'SOURCES'}
          subTitle={'Edit Source'}
        />
      </Row>
    {this.state.isLoading
    ?
      <div>
        <Loading size="64"/>
      </div>
    : ( !this.state.isLoading && !this.props.data.id )
    ?
      <div>
        <h3>Something has gone wrong</h3>
      </div>
    :
      <div>
        <Row>
          <div className="source-box">
            <div className="text">
              {this.props.data.source_type === 'Twitter' ?
              <TwitterIcon svgwidth={16} svgheight={16}/> :
              <RSSFeedIcon svgwidth={16} svgheight={16}/> }
              &nbsp;
              <strong>{this.props.data.source_type}</strong>
              {!this.state.editName &&
              <span className="rename" onClick={this.intoEditMode}>Edit</span>}
            </div>
            {this.state.editName
            ? <div className="source-edit-container">
              <div className="source-edit-fields">
                <div className="source-name">
                  <input autoFocus className="field-textbox"
                          value={this.state.name}
                          onChange={(e) => this.setState({name: e.target.value})} />
                  <div className="text grey">
                    <a href={this.sourceUrl()} target="_blank" rel="noopener">{this.sourceUrlText()}</a>
                  </div>
                </div>
                { this.state.hasSegmentAccess &&
                  <div className="source-segment">
                    <div className="text grey">
                      <strong>Segment</strong>
                    </div>
                    <div className="text grey">
                      <LoadSegmentCodes segmentCode={this.state.selectedSegmentCode} setSegmentCode={this.segmentCodeChanged}
                        setSegmentData={this.setSegmentData} hideLabel={true}></LoadSegmentCodes>
                    </div>
                  </div>
                }
              </div>
              <Button className="save"
                      onClick={(e) => this.saveChanges(e)}
                      disabled={this.state.isLoading ||
                        (!!this.state.existingSources.length &&
                        !isValidUpdatedSourceName(this.state.existingSources, this.state.name, this.state.id)
                        )}
                      >Save</Button>
              <Button className="cancel"
                      onClick={() => this.setState({editName: false, name: this.props.data.name})}>Cancel</Button>
            </div>
            : <div>
                <div className="text grey">
                  {this.props.data.name}
                </div>
                {this.state.hasSegmentAccess ?
                  <div className="text grey">
                    Segment: {this.props.data.community_partner_code || DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
                  </div> : null
                }
              </div>}
            {!this.state.isLoading && !!this.state.existingSources.length &&
            !isValidUpdatedSourceName(this.state.existingSources, this.state.name, this.state.id) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
          </div>
        </Row>
        <Row>
          <div className="filters-section">
            <h5>Filters for This Source</h5>
            <div className={this.state.hasIncludeExcludeConfigAccess ? 'left-bar' : ''}>
              <p>Activate or deactivate your filters for this source.</p>
              <p>
                Include filter - an article will <strong>only</strong> come in if it matches one of these topics.<br/>
                Exclude filter - <strong>no</strong> articles will come in that match one or more of these topic.<br/>
              </p>
            </div>
            {this.state.hasIncludeExcludeConfigAccess &&
              <div className="numeric-toggles">
                <div className="section">
                  <span className="toggle-title"># of includes</span>
                  <Input className="numberic-toggles-input" value={this.props.data.whitelist_matches}
                          min={1}
                          max={this.state.includeExcludeLimit > -1 ? this.state.includeExcludeLimit : 500 }
                          type="number"
                          name="includes"
                          onChange={(e) => this.onDataChange('whitelist_matches', e.target.value)}/>
                </div>
                <div className="section">
                  <span className="toggle-title"># of excludes</span>
                  <Input className="numberic-toggles-input" value={this.props.data.blacklist_matches}
                          min={1}
                          max={this.state.includeExcludeLimit > -1 ? this.state.includeExcludeLimit : 500 }
                          type="number"
                          name="includes"
                          onChange={(e) => this.onDataChange('blacklist_matches', e.target.value)}/>
                </div>
              </div>}
            <div className="clear">
              <TabMenu menus={FiltersMenus}
                    selectedItemKey="whitelist"
                    onSelect={({key}) => this.setState({key})} />
            </div>
            <div className="filters-grid rasa-data-grid">
              <Grid data={this.state.filters.filter((e: Filter) => e.type === this.state.key)}
              editField="is_active"
                    >
                <Column  field="is_active" title="Status" cell={this.FilterActiveCell} width="85" />
                <Column field="name" title="Topic, Keyword, or Phrase" editable={false} />
                <GridNoRecords><a href="/content/filters">Add a filter</a></GridNoRecords>
              </Grid>
            </div>
          </div>
        </Row>
      </div>
    }
    </div>)
  }

  public segmentCodeChanged = (code: any) => {
    this.setState({
      selectedSegmentCode: code.key,
    })
  }

  public setSegmentData = (segments: any[]) => {
    this.setState({
      segments,
    })
  }

  private sourceUrlText() {
    switch (this.props.data.source_type) {
      case SourceTypes.twitter : return `@${this.props.data.source_url}`
      default : return this.props.data.source_url
    }
  }

  private sourceUrl() {
    switch (this.props.data.source_type) {
      case SourceTypes.rss : return this.props.data.source_url
      case SourceTypes.twitter : return `https://www.twitter.com/${this.props.data.source_url}`
      default: return null
    }
  }

  private FilterActiveCell = (props: GridCellProps) => {
    return <ActiveCell {...props} onChange={this.toggleFilter} />
  }

  private onDataChange = (propertyName, data) => {
    this.propertyChanged(propertyName, data)
    this.saveRecord(this.props.data.id)
  }

  private toggleFilter = (e: any) => {
    return this.context.entityMetadata.getEntityObject('community_filtered_subscription')
              .then((entity) => {
                entity.setRecordId(this.state.communityId, e.dataItem.id)
                entity.data.isActive = !e.dataItem.is_active
                if (isNil(e.dataItem.id)) {
                  entity.data.subscriptionId = this.state.id
                  entity.data.filterGroupId = e.dataItem.filter_group_id
                  entity.data.filterType = e.dataItem.type
                }
                return entity.save().then(() => {
                  const filters = this.state.filters
                  filters[this.state.filters
                          .findIndex((record) => record === e.dataItem)].is_active = !e.dataItem.is_active
                  this.setState({filters})
                })
              })
  }

  private intoEditMode = () => {
    this.setState({editName: true, name: this.props.data.name})
  }

  private saveChanges = (e) => {
    this.propertyChanged('name', this.state.name)
    if (this.state.selectedSegmentCode) {
      this.propertyChanged('community_partner_code', this.state.selectedSegmentCode)
    }
    this.saveRecord(this.props.data.id).then(() => this.setState({editName: false}))
  }

}

export const ContentPoolEditSource = GenericRedux.registerNewNestedComponent(
  EditSourceComponent, 'edit_source')
