import { Grid, GridColumn as Column, GridDetailRowProps } from '@progress/kendo-react-grid'
import React, { FunctionComponent } from 'react'

import { Loading } from '../../../components/loading/component'
import { TabMenu } from '../../../components/tab-menu/component'
import { SourceTypes } from '../../../shared_server_client/constants'
import { ActiveCell } from '../kendocells/activeCell'

enum SourceLabels {
  all = 'All Sources',
  rss = 'Blog/RSS',
  facebook = 'Facebook',
  links = 'Links',
  youtube = 'YouTube',
}

const FACEBOOK_SOURCES = [SourceTypes.facebook]
const LINK_SOURCES = [SourceTypes.native, SourceTypes.scraper]
const RSS_SOURCES = [SourceTypes.rss]
const YOUTUBE_SOURCES = [SourceTypes.youtube]

const bySourceType = (label: SourceLabels) => {
  switch (label) {

    case SourceLabels.links:
      return ({source}) => LINK_SOURCES.includes(source)

    case SourceLabels.rss:
      return ({source}) => RSS_SOURCES.includes(source)

    case SourceLabels.facebook:
      return ({source}) => FACEBOOK_SOURCES.includes(source)

    case SourceLabels.youtube:
      return ({source}) => YOUTUBE_SOURCES.includes(source)

    default:
      return () => true
  }
}

type Props = GridDetailRowProps & {
  isLoadingSources: boolean,
  showSourceFilterType: any,
  toggleSubscription: any,
}

export const SourceFilterDetailComponent: FunctionComponent<Props> = ({
  dataItem,
  isLoadingSources,
  showSourceFilterType,
  toggleSubscription,
}) => {
  const {subscriptions: data, visibleSourceTypes} = dataItem
  if (isLoadingSources) {
    return <Loading size="32"></Loading>
  } else if (!data || data.length === 0) {
    return <div>Enter some more sources, and then apply some filters!</div>
  } else {
    return <div>
      <TabMenu
        menus={[
          {name: SourceLabels.all, key: SourceLabels.all},
          {name: SourceLabels.facebook, key: SourceLabels.facebook},
          {name: SourceLabels.rss, key: SourceLabels.rss},
          {name: SourceLabels.youtube, key: SourceLabels.youtube},
          {name: SourceLabels.links, key: SourceLabels.links},
        ]}
        selectedItemKey={dataItem.visibleSourceTypes || SourceLabels.all}
        onSelect={({key}) => showSourceFilterType(dataItem.id, key)}
        noDispatch={true}
      />
      <Grid
        data={data.filter(bySourceType(visibleSourceTypes))}
        onItemChange={
          (e) =>
            toggleSubscription(
              e.dataItem.id,
              e.dataItem.subscription_id,
              e.dataItem.filter_group_id,
              dataItem.type,
              e.value,
            )
          // toggleSubscription(dataItem, e.dataItem, e.value)
        }
      >
        <Column field="is_active" title="Active" cell={ActiveCell} width="85" />
        <Column field="source" title="Source" editable={false} />
        <Column field="name" title="Name" editable={false} />
        <Column field="source_url" title="Source URL" editable={false} />
      </Grid>
    </div>
  }
}
